<mat-toolbar color="primary">
    <mat-toolbar-row class="d-none d-sm-block">
        <img class="logo" [src]="LOGO_PLATAFORMA" width="50px" height="50px" alt="Logo Escuela" />
        <span>{{NOMBRE_ESCUELA}}</span>
        <span>
            <a href="/">
                <div class="btn btn-danger btn-menu" style="margin-left: 1em;"><i class="far fa-file-pdf"></i> INICIO</div>
            </a>
        </span>
    </mat-toolbar-row>
    <mat-toolbar-row class="d-block d-sm-none">
        <img class="logo" [src]="LOGO_PLATAFORMA" width="50px" height="50px" alt="Logo Escuela"/>
        <span>{{NOMBRE_PLATAFORMA}}</span>
        <span>
            <a href="/">
                <div class="btn btn-danger btn-menu" style="margin-left: 1em;"><i class="far fa-file-pdf"></i> INICIO</div>
            </a>
        </span>
    </mat-toolbar-row>
</mat-toolbar>
<ngx-spinner bdColor="rgba(51,51,51,1)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Cargando...</p>
</ngx-spinner>