import { Component, OnInit } from '@angular/core';
import { Registro, RegistroInfoResponse } from '../models/Registros';

import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { PreinscripcionesService } from '../servicios/preinscripciones.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-actualizacion',
  templateUrl: './actualizacion.component.html',
  styleUrls: ['./actualizacion.component.scss']
})
export class ActualizacionComponent implements OnInit {

  reg = {} as Registro;
  registro = {} as RegistroInfoResponse;
  admin: boolean = false;
  constructor(private spinner: NgxSpinnerService, private preinscripcionService: PreinscripcionesService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    var curp = this.route.snapshot.paramMap.get('curp');
    var apellidoP = this.route.snapshot.paramMap.get('apellidoP');
    var nombre = this.route.snapshot.paramMap.get('nombre');
    var admin = this.route.snapshot.paramMap.get('admin');

    if (curp && apellidoP && nombre) {
      this.reg.curp = curp;
      this.reg.apellidoP = apellidoP;
      this.reg.nombre = nombre;
      this.admin = admin == '1' ? true : false;

      this.ValidarUPD();
    }
  }

  ValidarUPD() {
    if (!this.reg.curp || !this.reg.apellidoP || !this.reg.nombre) {
      Swal.fire(
        'Preinscripciones',
        'Llene todos los campos.',
        'warning'
      );
      return;
    }
    var params = {
      curp: this.reg.curp,
      apellidoP: this.reg.apellidoP,
      nombre: this.reg.nombre
    };
    this.spinner.show();
    this.preinscripcionService.postInfoPreinscripcion(params).subscribe(response => {
      this.spinner.hide();

      if (response.success) {
        Swal.fire(
          'Actualización de datos de Preinscripción',
          response.message ? response.message : 'Bienvenido, ahora puede actualizar su información.',
          'success'
        );
        this.registro = response

        this.registro.registro.anio_egreso = this.registro.registro.anio_egreso.toString();
        this.registro.registro.extranjero = this.registro.registro.extranjero.toString();
      } else {
        Swal.fire(
          'Preinscripciones',
          response.message,
          'warning'
        );
        if(this.admin){
          this.registro = response
          this.registro.registro.anio_egreso = this.registro.registro.anio_egreso.toString();
          this.registro.registro.extranjero = this.registro.registro.extranjero.toString();
        }
      }
    });
  }

}
