import { Component, OnInit } from '@angular/core';
import { GlobalConstant } from '../utils/GlobalConstant';
import { Router } from '@angular/router';
import { GlobalEnviroments } from '../utils/GlobalEnvirontment';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  LOGO_PLATAFORMA:any;
  NOMBRE_PLATAFORMA:any;
  NOMBRE_ESCUELA:any;
  constructor(router: Router,public dialog: MatDialog) {
    this.LOGO_PLATAFORMA = GlobalConstant.LOGO_PLATAFORMA;
    this.NOMBRE_ESCUELA  =GlobalConstant.NOMBRE_ESCUELA
    this.NOMBRE_PLATAFORMA  =GlobalConstant.NOMBRE_PLATAFORMA
  }

  ngOnInit(): void {

  }

}
