<app-menu *ngIf="!edicion"></app-menu>

<div class="container cuerpo">

    <div class="row">

        <div class="col-md-12 text-center" *ngIf="preinscripcion">
            <h1><b>{{preinscripcion.titulo}}</b></h1>
            <img width="100dp" src="assets/Registro.svg" alt="Registro Solicitud" />
        </div>

        <div class="col-md-12" *ngIf="preinscripcion">
            <h4><b>INSTRUCCIONES:</b></h4>
            <p>{{preinscripcion.instrucciones}}</p>
        </div>


        <form name="form" class="col-12 text-center" #f="ngForm" novalidate (ngSubmit)="RegistrarSolicitud(f)">

            <div class="col-md-12">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <p class="panel-title">Datos Personales</p>
                    </div>
                    <div class="panel-body">

                        <div class="col-md-4">
                            <mat-form-field appearance="fill">
                                <mat-label>¿Eres Extranjero?</mat-label>
                                <mat-select [(ngModel)]="reg.extranjero" #extranjero="ngModel" name="extranjero"
                                    required (selectionChange)="esExtrangero()" [disabled]="edicion && !admin">
                                    <mat-option value="0" selected>NO</mat-option>
                                    <mat-option value="0">Soy Extranjero y si tengo Curp</mat-option>
                                    <mat-option value="1">Soy extranjero y no tengo Curp</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="extranjero.invalid && (extranjero.dirty || extranjero.touched)"
                                class="alert alert-danger">

                                <div *ngIf="extranjero.errors?.['required']">
                                    Seleccione una opción de la lista.
                                </div>
                            </div>
                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>CURP (En Mayusculas):</mat-label>
                                    <input matInput [(ngModel)]="reg.curp" #curp="ngModel" name="curp" required
                                        minlength="1" maxlength="18" [disabled]="reg.extranjero == '1'"
                                        [disabled]="edicion && !admin">
                                </mat-form-field>
                                <div *ngIf="curp.invalid && (curp.dirty || curp.touched)" class="alert alert-danger">

                                    <div *ngIf="curp.errors?.['required']">
                                        Escriba su curp.
                                    </div>
                                    <div *ngIf="curp.errors?.['minlength']">
                                        Escribir a 18 caracteres.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field appearance="fill">
                                    <mat-label>Estado Civil</mat-label>
                                    <mat-select [(ngModel)]="reg.estadocivil" name="estadocivil" #estadocivil="ngModel"
                                        required>
                                        <mat-option value="SOLTERO">SOLTERO</mat-option>
                                        <mat-option value="CASADO">CASADO</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="estadocivil.invalid && (estadocivil.dirty || estadocivil.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="estadocivil.errors?.['required']">
                                        Seleccione una opción de la lista.
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Nombre(s):</mat-label>
                                    <input matInput [(ngModel)]="reg.nombre" #nombre="ngModel" name="nombre" required
                                        [disabled]="edicion && !admin">
                                </mat-form-field>
                                <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="nombre.errors?.['required']">
                                        Escriba su nombre(s).
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Primer Apellido:</mat-label>
                                    <input matInput [(ngModel)]="reg.apellidoP" #apellidoP="ngModel" name="apellidoP"
                                        required [disabled]="edicion && !admin">
                                </mat-form-field>
                                <div *ngIf="apellidoP.invalid && (apellidoP.dirty || apellidoP.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="apellidoP.errors?.['required']">
                                        Escriba su primer apellido.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Segundo Apellido:</mat-label>
                                    <input matInput [(ngModel)]="reg.apellidoM" name="apellidoM"
                                        [disabled]="edicion && !admin">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Genero:</mat-label>
                                    <mat-select [(ngModel)]="reg.genero" #genero="ngModel" name="genero" required>
                                        <mat-option value="H">Hombre</mat-option>
                                        <mat-option value="M">Mujer</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="genero.invalid && (genero.dirty || genero.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="genero.errors?.['required']">
                                        Seleccione su genero.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Fecha de Nacimiento:</mat-label>
                                    <input matInput type="date" [(ngModel)]="reg.fechaNac" #fechaNac="ngModel"
                                        name="fechaNac" required>
                                </mat-form-field>
                                <div *ngIf="fechaNac.invalid && (fechaNac.dirty || fechaNac.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="fechaNac.errors?.['required']">
                                        Proporcione su fecha de nacimiento.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Estado de Nacimiento:</mat-label>
                                    <input matInput [(ngModel)]="reg.estado" #estado="ngModel" name="estado" required>
                                </mat-form-field>
                                <div *ngIf="estado.invalid && (estado.dirty || estado.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="estado.errors?.['required']">
                                        Escriba su estado de nacimiento.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label> Municipio de Nacimiento:</mat-label>
                                    <input matInput [(ngModel)]="reg.municipio" #municipio="ngModel" name="municipio"
                                        required>
                                </mat-form-field>
                                <div *ngIf="municipio.invalid && (municipio.dirty || municipio.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="municipio.errors?.['required']">
                                        Escriba su municipio de nacimiento.
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-md-12" style="margin-top: 1em;">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <p class="panel-title">Datos de Contacto</p>
                    </div>
                    <div class="panel-body">

                        <div class="row col-md-12">
                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Calle y Número:</mat-label>
                                    <input matInput [(ngModel)]="reg.calle" #calle="ngModel" name="calle" required>
                                </mat-form-field>
                                <div *ngIf="calle.invalid && (calle.dirty || calle.touched)" class="alert alert-danger">

                                    <div *ngIf="calle.errors?.['required']">
                                        Escriba su calle con número.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Colonia:</mat-label>
                                    <input matInput [(ngModel)]="reg.colonia" #colonia="ngModel" name="colonia"
                                        required>
                                </mat-form-field>
                                <div *ngIf="colonia.invalid && (colonia.dirty || colonia.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="colonia.errors?.['required']">
                                        Escriba su colonia/fraccionamiento.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Localidad:</mat-label>
                                    <input matInput [(ngModel)]="reg.localidad" #localidad="ngModel" name="localidad"
                                        required>
                                </mat-form-field>
                                <div *ngIf="localidad.invalid && (localidad.dirty || localidad.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="localidad.errors?.['required']">
                                        Escriba su localidad.
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Correo Personal:</mat-label>
                                    <input matInput [(ngModel)]="reg.correo" #correo="ngModel" name="correo" required
                                        placeholder="EJEMPLO: xx@xx.com" type="email">
                                </mat-form-field>
                                <div *ngIf="correo.invalid && (correo.dirty || correo.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="correo.errors?.['required']">
                                        Escriba un correo personal de contacto.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefono Fijo (10 Digitos):</mat-label>
                                    <input matInput type="text" mask="0000000000" [(ngModel)]="reg.telefono_fijo"
                                        #telefono_fijo="ngModel" name="telefono_fijo" placeholder="EJEMPLO: 6181234567"
                                        minlength="10" maxlength="10">
                                </mat-form-field>
                                <div *ngIf="telefono_fijo.invalid && (telefono_fijo.dirty || telefono_fijo.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="telefono_fijo.errors?.['required']">
                                        Escribir a 10 digitos sin guiones y sin espacios.
                                    </div>
                                    <div *ngIf="telefono_fijo.errors?.['minlength']">
                                        Escribir a 10 digitos.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefono Celular del Aspirante (10 Digitos):</mat-label>
                                    <input matInput type="text" mask="0000000000" [(ngModel)]="reg.telefono_celular"
                                        #telefono_celular="ngModel" name="telefono_celular" maxlength="10"
                                        minlength="10" required placeholder="EJEMPLO: 6181234567">
                                </mat-form-field>
                                <div *ngIf="telefono_celular.invalid && (telefono_celular.dirty || telefono_celular.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="telefono_celular.errors?.['required']">
                                        Escribir a 10 digitos sin guiones y sin espacios.
                                    </div>
                                    <div *ngIf="telefono_celular.errors?.['minlength']">
                                        Escribir a 10 digitos.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Codigo Postal (5 Digitos):</mat-label>
                                    <input matInput mask="00000" [(ngModel)]="reg.cp" type="text" name="cp"
                                        #cp="ngModel" required minlength="5" maxlength="5">
                                </mat-form-field>
                                <div *ngIf="cp.invalid && (cp.dirty || cp.touched)" class="alert alert-danger">

                                    <div *ngIf="cp.errors?.['required']">
                                        Escribir su codigo postal.
                                    </div>
                                    <div *ngIf="cp.errors?.['minlength']">
                                        Usar 5 digitos.
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <!--Datos de Salud-->
            <div class="col-md-12" style="margin-top: 1em;">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <p class="panel-title">Datos de Salud</p>
                    </div>
                    <div class="panel-body">

                        <div class="col-md-12">
                            <p>Si tienes institución que te proporcione servicios médicos, proporciona tus datos
                                a
                                continuación.</p>
                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-4">
                                <mat-form-field appearance="fill">
                                    <mat-label>Seguridad Social:</mat-label>
                                    <mat-select [(ngModel)]="reg.seguro" name="seguro" #seguro="ngModel" required>
                                        <mat-option value="NINGUNO">NINGUNO</mat-option>
                                        <mat-option value="IMSS">IMSS</mat-option>
                                        <mat-option value="ISSSTE">ISSSTE</mat-option>
                                        <mat-option value="OTRO">OTRO</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="seguro.invalid && (seguro.dirty || seguro.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="seguro.errors?.['required']">
                                        Seleccione uno de la lista.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Número de Seguridad Social (NSS):</mat-label>
                                    <input matInput [(ngModel)]="reg.nss" name="nss">
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field appearance="fill">
                                    <mat-label>Tipo de Discapacidad:</mat-label>
                                    <mat-select [(ngModel)]="reg.discapacidad" name="discapacidad"
                                        #discapacidad="ngModel" required>
                                        <mat-option value="NINGUNO">NINGUNO</mat-option>
                                        <mat-option value="DISCAPACIDAD VISUAL">DISCAPACIDAD VISUAL (miopía o
                                            astigmatismo no aplica)
                                        </mat-option>
                                        <mat-option value="DISCAPACIDAD AUDITIVA">DISCAPACIDAD AUDITIVA
                                        </mat-option>
                                        <mat-option value="DISCAPACIDAD DE LENGUAJE">DISCAPACIDAD DE LENGUAJE
                                        </mat-option>
                                        <mat-option value="DISCAPACIDAD MOTRIZ">DISCAPACIDAD MOTRIZ</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <small>Tu respuesta en esta pregunta no afecta tu resultado en el proceso de
                                    preinscripción, solo se requiere con fines estadísticos.</small>
                                <div *ngIf="discapacidad.invalid && (discapacidad.dirty || discapacidad.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="discapacidad.errors?.['required']">
                                        Seleccione uno de la lista.
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <!--Datos de Secundaria-->

            <div class="col-md-12" style="margin-top: 1em;">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <p class="panel-title">Escuela Secundaria de Procedencia</p>
                    </div>
                    <div class="panel-body">

                        <div class="row col-md-12">
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Nombre de escuela:</mat-label>
                                    <input matInput [(ngModel)]="reg.escuela_procedencia" #escuela_procedencia="ngModel"
                                        name="escuela_procedencia" required [matAutocomplete]="auto">
                                </mat-form-field>
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option disabled="true">Seleccione uno de la lista o escriba el nombre de su
                                        escuela.</mat-option>
                                    <mat-option value="CADI ERICH FROMM">CADI ERICH FROMM</mat-option>
                                    <mat-option value="SECUNDARIA ANEXA A LA BYCENED">SECUNDARIA ANEXA A LA BYCENED
                                    </mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA ESTATAL GRAL. FRANCISCO VILLA ">ESCUELA
                                        SECUNDARIA ESTATAL GRAL. FRANCISCO VILLA </mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA ESTATAL LIC. BENITO JUAREZ">ESCUELA SECUNDARIA
                                        ESTATAL LIC. BENITO JUAREZ</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA ESTATAL QUETZALCOATL">ESCUELA SECUNDARIA
                                        ESTATAL QUETZALCOATL</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA ESTATAL REVOLUCION EDUCATIVA">ESCUELA
                                        SECUNDARIA ESTATAL REVOLUCION EDUCATIVA</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA GENERAL JAIME TORRES BODET">ESCUELA SECUNDARIA
                                        GENERAL JAIME TORRES BODET</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA GENERAL MOISES SAENZ No. 7">ESCUELA SECUNDARIA
                                        GENERAL MOISES SAENZ No. 7</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA GRAL GUADALUPE VICTORIA No. 5">ESCUELA
                                        SECUNDARIA GRAL GUADALUPE VICTORIA No. 5</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA GRAL. RAMON LOPEZ VELARDE">ESCUELA SECUNDARIA
                                        GRAL. RAMON LOPEZ VELARDE</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA IGNACIO MANUEL ALTAMIRANO - ESIMA">ESCUELA
                                        SECUNDARIA IGNACIO MANUEL ALTAMIRANO - ESIMA</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA JOSE VASCONCELOS No. 4">ESCUELA SECUNDARIA
                                        JOSE VASCONCELOS No. 4</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA TECNICA No. 1">ESCUELA SECUNDARIA TECNICA No.
                                        1</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA TECNICA No. 19">ESCUELA SECUNDARIA TECNICA No.
                                        19</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA TECNICA No. 53 ">ESCUELA SECUNDARIA TECNICA
                                        No. 53 </mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA TECNICA No. 62">ESCUELA SECUNDARIA TECNICA No.
                                        62</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA TECNICA No. 67">ESCUELA SECUNDARIA TECNICA No.
                                        67</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA TECNICA No. 73">ESCUELA SECUNDARIA TECNICA No.
                                        73</mat-option>
                                    <mat-option value="ESCUELA SECUNDARIA TECNICA No. 57">ESCUELA SECUNDARIA TECNICA No.
                                        57</mat-option>
                                    <mat-option value="COLEGIO ANGLO ESPAÑOL">COLEGIO ANGLO ESPAÑOL</mat-option>
                                    <mat-option value="COLEGIO DEL REAL">COLEGIO DEL REAL</mat-option>
                                    <mat-option value="COLEGIO DUNOR">COLEGIO DUNOR</mat-option>
                                    <mat-option value="COLEGIO ESPAÑA">COLEGIO ESPAÑA</mat-option>
                                    <mat-option value="COLEGIO FRAY DIEGO DE LA CADENA">COLEGIO FRAY DIEGO DE LA CADENA
                                    </mat-option>
                                    <mat-option value="COLEGIO GUADIANA LASALLE">COLEGIO GUADIANA LASALLE</mat-option>
                                    <mat-option value="COLEGIO HISPANO AMERICANO">COLEGIO HISPANO AMERICANO</mat-option>
                                    <mat-option value="COLEGIO INGLES DE DURANGO">COLEGIO INGLES DE DURANGO</mat-option>
                                    <mat-option value="COLEGIO INSURGENTES">COLEGIO INSURGENTES</mat-option>
                                    <mat-option value="COLEGIO JOSE MARIA GONZALEZ Y VALENCIA">COLEGIO JOSE MARIA
                                        GONZALEZ Y VALENCIA</mat-option>
                                    <mat-option value="COLEGIO JUAN PABLO II">COLEGIO JUAN PABLO II</mat-option>
                                    <mat-option value="COLEGIO LIBERTADORES">COLEGIO LIBERTADORES</mat-option>
                                    <mat-option value="COLEGIO MAC DONELL DE DURANGO">COLEGIO MAC DONELL DE DURANGO
                                    </mat-option>
                                    <mat-option value="COLEGIO MIGUEL DE CERVANTES SAAVEDRA">COLEGIO MIGUEL DE CERVANTES
                                        SAAVEDRA</mat-option>
                                    <mat-option value="COLEGIO MONTESSORI">COLEGIO MONTESSORI</mat-option>
                                    <mat-option value="COLEGIO MOTOLINIA">COLEGIO MOTOLINIA</mat-option>
                                    <mat-option value="COLEGIO REX">COLEGIO REX</mat-option>
                                    <mat-option value="COLEGIO SOR JUANA INES DE LA CRUZ">COLEGIO SOR JUANA INES DE LA
                                        CRUZ</mat-option>
                                    <mat-option value="COLEGIO TERESA DE AVILA">COLEGIO TERESA DE AVILA</mat-option>
                                    <mat-option value="COLEGIO VALLADOLID">COLEGIO VALLADOLID</mat-option>
                                    <mat-option value="OTRO">OTRO</mat-option>
                                </mat-autocomplete>
                                <div *ngIf="escuela_procedencia.invalid && (escuela_procedencia.dirty || escuela_procedencia.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="escuela_procedencia.errors?.['required']">
                                        Escriba el nombre de su escuela de procedencia.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Año de egreso:</mat-label>
                                    <mat-select [(ngModel)]="reg.anio_egreso" name="anio_egreso" #anio_egreso="ngModel"
                                        required>
                                        <mat-option value="2020">2020</mat-option>
                                        <mat-option value="2021">2021</mat-option>
                                        <mat-option value="2022">2022</mat-option>
                                        <mat-option value="2023">2023</mat-option>
                                        <mat-option value="2024">2024</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="anio_egreso.invalid && (anio_egreso.dirty || anio_egreso.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="anio_egreso.errors?.['required']">
                                        Seleccione uno de la lista.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Promedio hasta tercero:</mat-label>
                                    <input matInput [(ngModel)]="reg.promedio" name="promedio" #promedio="ngModel"
                                        required maxlength="3" (keypress)="validarDigitos($event)"
                                        (change)="validarPromedio()" placeholder="EJEMPLO: 9.3">
                                </mat-form-field>
                                <small>* Sumar el promedio de primero más el promedio de segundo, más lo que llevas
                                    hasta tercero y dividirlo entre tres</small>
                                <div *ngIf="promedio.invalid && (promedio.dirty || promedio.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="promedio.errors?.['required']">
                                        Escriba el promedio final de secundaria.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Tipo de Regimen:</mat-label>
                                    <mat-select [(ngModel)]="reg.tiporegimen" name="tiporegimen" #tiporegimen="ngModel"
                                        required>
                                        <mat-option value="PUBLICO">PÚBLICO</mat-option>
                                        <mat-option value="PRIVADO">PRIVADO</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="tiporegimen.invalid && (tiporegimen.dirty || tiporegimen.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="tiporegimen.errors?.['required']">
                                        Seleccione una opción.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Clave de la Secundaria:</mat-label>
                                    <input matInput type="text" type="text" [(ngModel)]="reg.clavesecu"
                                        name="clavesecu">
                                </mat-form-field>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Modalidad:</mat-label>
                                    <mat-select [(ngModel)]="reg.modalidad" name="modalidad" #modalidad="ngModel">
                                        <mat-option value="GENERAL">GENERAL</mat-option>
                                        <mat-option value="TECNICA">TÉCNICA</mat-option>
                                        <mat-option value="INEA">INEA</mat-option>
                                        <mat-option value="ABIERTA">ABIERTA</mat-option>
                                        <mat-option value="TELESECUNDARIA">TELESECUNDARIA</mat-option>
                                        <mat-option value="REVALIDACION">REVALIDACIÓN</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="modalidad.invalid && (modalidad.dirty || modalidad.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="modalidad.errors?.['required']">
                                        Seleccione una opción.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <mat-form-field appearance="fill">
                                    <mat-label>Disciplinas culturales o deportivas practicadas:</mat-label>
                                    <mat-select [(ngModel)]="reg.disciplinas" #disciplinas="ngModel" name="disciplinas"
                                        multiple>
                                        <mat-option value="NINGUNA DE LA LISTA">NINGUNA DE LA LISTA</mat-option>
                                        <mat-option value="AJEDREZ">AJEDREZ</mat-option>
                                        <mat-option value="ARTESANIAS">ARTESANIAS</mat-option>
                                        <mat-option value="CANTO">CANTO</mat-option>
                                        <mat-option value="CREACION LITERARIA">CREACIÓN LITERARIA</mat-option>
                                        <mat-option value="DANZA">DANZA</mat-option>
                                        <mat-option value="DECLAMACION">DECLAMACIÓN</mat-option>
                                        <mat-option value="FOTOGRAFIA">FOTOGRAFÍA</mat-option>
                                        <mat-option value="ORATORIA">ORATORIA</mat-option>
                                        <mat-option value="PINTURA O DIBUJO">PINTURA O DIBUJO</mat-option>
                                        <mat-option value="TEATRO">TEATRO</mat-option>
                                        <mat-option value="ESCOLTA">ESCOLTA</mat-option>
                                        <mat-option value="ATLETISMO">ATLETISMO</mat-option>
                                        <mat-option value="BASQUETBOL">BASQUETBOL</mat-option>
                                        <mat-option value="BEISBOL">BEISBOL</mat-option>
                                        <mat-option value="FUTBOL">FUTBOL</mat-option>
                                        <mat-option value="VOLIBOL">VOLIBOL</mat-option>
                                        <mat-option value="SOFTBOL">SOFTBOL</mat-option>
                                        <mat-option value="RONDALLA">RONDALLA</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <small class="d-block d-sm-none small-movil">(Para cerrar despues de seleccionar, toca
                                    con el dedo en cualquier parte de la pantalla afuera de la seleccion).</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Tutotes-->

            <div class="col-md-12" style="margin-top: 1em;">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <p class="panel-title">Tutores</p>
                    </div>
                    <div class="panel-body">
                        <div class="col-md-12">
                            <p>TUTORES: Por razones de localización se requiere que captures el nombre de 3
                                personas que
                                respondan por ti, priorizando a tus padres o tutores legales.</p>
                        </div>
                        <div class="row col-md-12">
                            <div class="col-md-8">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Tutor 1:</mat-label>
                                    <input matInput [(ngModel)]="reg.tutor1" name="tutor1" #tutor1="ngModel" required>
                                </mat-form-field>
                                <div *ngIf="tutor1.invalid && (tutor1.dirty || tutor1.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="tutor1.errors?.['required']">
                                        Escriba el nombre del tutor.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field appearance="fill">
                                    <mat-label>Parentesco:</mat-label>
                                    <mat-select [(ngModel)]="reg.parentesco1" name="parentesco1" #parentesco1="ngModel"
                                        required>
                                        <mat-option value="Padre">Padre</mat-option>
                                        <mat-option value="Madre">Madre</mat-option>
                                        <mat-option value="Tio">Tio</mat-option>
                                        <mat-option value="Tia">Tia</mat-option>
                                        <mat-option value="Abuelo">Abuelo</mat-option>
                                        <mat-option value="Abuela">Abuela</mat-option>
                                        <mat-option value="Hermano">Hermano</mat-option>
                                        <mat-option value="Hermana">Hermana</mat-option>
                                        <mat-option value="Otro">Otro</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="parentesco1.invalid && (parentesco1.dirty || parentesco1.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="parentesco1.errors?.['required']">
                                        Seleccione un parentesco.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefono (10 Digitos):</mat-label>
                                    <input matInput type="text" mask="0000000000" [(ngModel)]="reg.telefono1"
                                        name="telefono1" #telefono1="ngModel" required maxlength="10" minlength="10"
                                        placeholder="EJEMPLO: 6181234567">
                                </mat-form-field>
                                <div *ngIf="telefono1.invalid && (telefono1.dirty || telefono1.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="telefono1.errors?.['required']">
                                        Escribir a 10 digitos sin guiones y sin espacios.
                                    </div>
                                    <div *ngIf="telefono1.errors?.['minlength']">
                                        Escribir a 10 digitos.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-8">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Tutor 2:</mat-label>
                                    <input matInput [(ngModel)]="reg.tutor2" name="tutor2" #tutor2="ngModel" required>
                                </mat-form-field>
                                <div *ngIf="tutor2.invalid && (tutor2.dirty || tutor2.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="tutor2.errors?.['required']">
                                        Escriba el nombre del tutor.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field appearance="fill">
                                    <mat-label>Parentesco:</mat-label>
                                    <mat-select [(ngModel)]="reg.parentesco2" name="parentesco2" #parentesco2="ngModel"
                                        required>
                                        <mat-option value="Padre">Padre</mat-option>
                                        <mat-option value="Madre">Madre</mat-option>
                                        <mat-option value="Tio">Tio</mat-option>
                                        <mat-option value="Tia">Tia</mat-option>
                                        <mat-option value="Abuelo">Abuelo</mat-option>
                                        <mat-option value="Abuela">Abuela</mat-option>
                                        <mat-option value="Hermano">Hermano</mat-option>
                                        <mat-option value="Hermana">Hermana</mat-option>
                                        <mat-option value="Otro">Otro</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="parentesco2.invalid && (parentesco2.dirty || parentesco2.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="parentesco2.errors?.['required']">
                                        Seleccione un parentesco.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefono (10 Digitos):</mat-label>
                                    <input matInput type="text" mask="0000000000" [(ngModel)]="reg.telefono2"
                                        name="telefono2" #telefono2="ngModel" maxlength="10" minlength="10" required
                                        placeholder="EJEMPLO: 6181234567">
                                </mat-form-field>
                                <div *ngIf="telefono2.invalid && (telefono2.dirty || telefono2.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="telefono2.errors?.['required']">
                                        Escribir a 10 digitos sin guiones y sin espacios.
                                    </div>
                                    <div *ngIf="telefono2.errors?.['minlength']">
                                        Escribir a 10 digitos.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-8">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Tutor 3:</mat-label>
                                    <input matInput [(ngModel)]="reg.tutor3" name="tutor3" #tutor3="ngModel" required>
                                </mat-form-field>
                                <div *ngIf="tutor3.invalid && (tutor3.dirty || tutor3.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="tutor3.errors?.['required']">
                                        Escriba el nombre del tutor.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field appearance="fill">
                                    <mat-label>Parentesco:</mat-label>
                                    <mat-select [(ngModel)]="reg.parentesco3" name="parentesco3" #parentesco3="ngModel"
                                        required>
                                        <mat-option value="Padre">Padre</mat-option>
                                        <mat-option value="Madre">Madre</mat-option>
                                        <mat-option value="Tio">Tio</mat-option>
                                        <mat-option value="Tia">Tia</mat-option>
                                        <mat-option value="Abuelo">Abuelo</mat-option>
                                        <mat-option value="Abuela">Abuela</mat-option>
                                        <mat-option value="Hermano">Hermano</mat-option>
                                        <mat-option value="Hermana">Hermana</mat-option>
                                        <mat-option value="Otro">Otro</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="parentesco3.invalid && (parentesco3.dirty || parentesco3.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="parentesco3.errors?.['required']">
                                        Seleccione un parentesco.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefono (10 Digitos):</mat-label>
                                    <input matInput type="text" mask="0000000000" [(ngModel)]="reg.telefono3"
                                        name="telefono3" #telefono3="ngModel" maxlength="10" minlength="10" required
                                        placeholder="EJEMPLO: 6181234567">
                                </mat-form-field>
                                <div *ngIf="telefono3.invalid && (telefono3.dirty || telefono3.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="telefono3.errors?.['required']">
                                        Escribir a 10 digitos sin guiones y sin espacios.
                                    </div>
                                    <div *ngIf="telefono3.errors?.['minlength']">
                                        Escribir a 10 digitos.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!--Opciones de Carrera-->

            <div class="col-md-12" style="margin-top: 1em;">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <p class="panel-title">Opciones de Carrera</p>
                    </div>
                    <div class="panel-body">

                        <div class="row col-md-12">
                            <div class="col-md-6">

                                <mat-form-field appearance="fill">
                                    <mat-label>Opcion 1:</mat-label>
                                    <mat-select [(ngModel)]="reg.carrera1" name="carrera1" #carrera1="ngModel" required>
                                        <mat-option
                                            value="ADMINISTRACIÓN DE RECURSOS HUMANOS TURNO MATUTINO">ADMINISTRACIÓN DE
                                            RECURSOS HUMANOS TURNO MATUTINO</mat-option>
                                        <mat-option
                                            value="ADMINISTRACIÓN DE RECURSOS HUMANOS TURNO VESPERTINO">ADMINISTRACIÓN
                                            DE RECURSOS HUMANOS TURNO VESPERTINO
                                        </mat-option>
                                        <mat-option value="ENFERMERÍA TURNO VESPERTINO">ENFERMERÍA TURNO
                                            VESPERTINO</mat-option>
                                        <mat-option value="LABORATORISTA CLÍNICO TURNO MATUTINO">LABORATORISTA CLÍNICO
                                            TURNO MATUTINO</mat-option>
                                        <mat-option value="LABORATORISTA CLÍNICO TURNO VESPERTINO">LABORATORISTA CLÍNICO
                                            TURNO VESPERTINO</mat-option>
                                        <mat-option value="LOGÍSTICA TURNO MATUTINO">LOGÍSTICA TURNO
                                            MATUTINO</mat-option>
                                        <mat-option value="PRODUCCIÓN INDUSTRIAL DE ALIMENTOS TURNO MATUTINO">PRODUCCIÓN
                                            INDUSTRIAL DE ALIMENTOS TURNO MATUTINO</mat-option>
                                        <mat-option
                                            value="PRODUCCIÓN INDUSTRIAL DE ALIMENTOS TURNO VESPERTINO">PRODUCCIÓN
                                            INDUSTRIAL DE ALIMENTOS TURNO VESPERTINO</mat-option>

                                        <mat-option value="PROGRAMACIÓN TURNO MATUTINO">PROGRAMACIÓN TURNO
                                            MATUTINO</mat-option>
                                        <mat-option value="PROGRAMACIÓN TURNO VESPERTINO">PROGRAMACIÓN TURNO
                                            VESPERTINO</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="carrera1.invalid && (carrera1.dirty || carrera1.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="carrera1.errors?.['required']">
                                        Seleccione una opción.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">

                                <mat-form-field appearance="fill">
                                    <mat-label>Opcion 2:</mat-label>
                                    <mat-select [(ngModel)]="reg.carrera2" name="carrera2" #carrera2="ngModel" required>
                                        <mat-option
                                            value="ADMINISTRACIÓN DE RECURSOS HUMANOS TURNO MATUTINO">ADMINISTRACIÓN DE
                                            RECURSOS HUMANOS TURNO MATUTINO</mat-option>
                                        <mat-option
                                            value="ADMINISTRACIÓN DE RECURSOS HUMANOS TURNO VESPERTINO">ADMINISTRACIÓN
                                            DE RECURSOS HUMANOS TURNO VESPERTINO
                                        </mat-option>
                                        <mat-option value="ENFERMERÍA TURNO VESPERTINO">ENFERMERÍA TURNO
                                            VESPERTINO</mat-option>
                                        <mat-option value="LABORATORISTA CLÍNICO TURNO MATUTINO">LABORATORISTA CLÍNICO
                                            TURNO MATUTINO</mat-option>
                                        <mat-option value="LABORATORISTA CLÍNICO TURNO VESPERTINO">LABORATORISTA CLÍNICO
                                            TURNO VESPERTINO</mat-option>
                                        <mat-option value="LOGÍSTICA TURNO MATUTINO">LOGÍSTICA TURNO
                                            MATUTINO</mat-option>
                                        <mat-option value="PRODUCCIÓN INDUSTRIAL DE ALIMENTOS TURNO MATUTINO">PRODUCCIÓN
                                            INDUSTRIAL DE ALIMENTOS TURNO MATUTINO</mat-option>
                                        <mat-option
                                            value="PRODUCCIÓN INDUSTRIAL DE ALIMENTOS TURNO VESPERTINO">PRODUCCIÓN
                                            INDUSTRIAL DE ALIMENTOS TURNO VESPERTINO</mat-option>

                                        <mat-option value="PROGRAMACIÓN TURNO MATUTINO">PROGRAMACIÓN TURNO
                                            MATUTINO</mat-option>
                                        <mat-option value="PROGRAMACIÓN TURNO VESPERTINO">PROGRAMACIÓN TURNO
                                            VESPERTINO</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="carrera2.invalid && (carrera2.dirty || carrera2.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="carrera2.errors?.['required']">
                                        Seleccione una opción.
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row col-md-12">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Opcion 3:</mat-label>
                                    <mat-select [(ngModel)]="reg.carrera3" name="carrera3" #carrera3="ngModel" required>
                                        <mat-option
                                            value="ADMINISTRACIÓN DE RECURSOS HUMANOS TURNO MATUTINO">ADMINISTRACIÓN DE
                                            RECURSOS HUMANOS TURNO MATUTINO</mat-option>
                                        <mat-option
                                            value="ADMINISTRACIÓN DE RECURSOS HUMANOS TURNO VESPERTINO">ADMINISTRACIÓN
                                            DE RECURSOS HUMANOS TURNO VESPERTINO
                                        </mat-option>
                                        <mat-option value="ENFERMERÍA TURNO VESPERTINO">ENFERMERÍA TURNO
                                            VESPERTINO</mat-option>
                                        <mat-option value="LABORATORISTA CLÍNICO TURNO MATUTINO">LABORATORISTA CLÍNICO
                                            TURNO MATUTINO</mat-option>
                                        <mat-option value="LABORATORISTA CLÍNICO TURNO VESPERTINO">LABORATORISTA CLÍNICO
                                            TURNO VESPERTINO</mat-option>
                                        <mat-option value="LOGÍSTICA TURNO MATUTINO">LOGÍSTICA TURNO
                                            MATUTINO</mat-option>
                                        <mat-option value="PRODUCCIÓN INDUSTRIAL DE ALIMENTOS TURNO MATUTINO">PRODUCCIÓN
                                            INDUSTRIAL DE ALIMENTOS TURNO MATUTINO</mat-option>
                                        <mat-option
                                            value="PRODUCCIÓN INDUSTRIAL DE ALIMENTOS TURNO VESPERTINO">PRODUCCIÓN
                                            INDUSTRIAL DE ALIMENTOS TURNO VESPERTINO</mat-option>

                                        <mat-option value="PROGRAMACIÓN TURNO MATUTINO">PROGRAMACIÓN TURNO
                                            MATUTINO</mat-option>
                                        <mat-option value="PROGRAMACIÓN TURNO VESPERTINO">PROGRAMACIÓN TURNO
                                            VESPERTINO</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="carrera3.invalid && (carrera3.dirty || carrera3.touched)"
                                    class="alert alert-danger">

                                    <div *ngIf="carrera3.errors?.['required']">
                                        Seleccione una opción.
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <!--Boton Enviar-->
            <div class="col-md-12 text-center mensaje" style="margin-top: 1em;" *ngIf="!f.form.valid">
                <h2>Llene correctamente todos los campos marcados con * .</h2>
            </div>
            <div class="col-md-12 text-center" style="margin-top: 1em;" *ngIf="!edicion">
                <button mat-raised-button color="accent" type="submit"
                    [disabled]="!f.form.valid"><mat-icon>send</mat-icon> Enviar
                    Registro</button>
            </div>
            <div class="col-md-12 text-center" style="margin-top: 1em;" *ngIf="edicion">
                <button mat-raised-button color="accent" type="submit" [disabled]="!f.form.valid">Actualizar
                    Registro</button>
            </div>

        </form>

    </div>
</div>